@import './styles/colors';
@import './styles/mixin';

.Layout {
  @extend .display-flex;
  @extend .flex-center;
}

.Container {
  @extend .responsive-width;
  @extend .bsbb;
  padding: 20px;
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
}

.Logo {
  max-width: 80%;
}

.License {
  margin-top: 50px;
  font-size: 18px;
  background: $white;
  width: 100%;
  @extend .bsbb;
  padding: 10px;
  @include border-radius(10px);
  line-height: 1.5;
  i {
    color: $amber;
    font-size: 24px;
    margin-right: 10px;
  }
  b {
    font-weight: 600;
    text-transform: uppercase;
  }
  a {
    font-size: 18px;
    color: $blue;
    text-decoration: none;
    font-weight: 600;
  }
}

.Stealth {
  margin: 50px 0;
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  span {
    display: block;
    width: 100%;
    text-transform: uppercase;
    color: $blue-grey-900;
    font-weight: 600;
  }
  code {
    width: 100%;
    @include border-radius(10px);
    background: $white;
    padding: 10px;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    @extend .bsbb;
    margin: 10px 0;
    font-size: 16px;
    font-family: 'Cutive Mono', monospace;
    min-height: 36px;
  }
}

.Footer {
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  font-size: 12px;
  img {
    margin: 20px 0;
    width: 50px;
  }
}

.LoaderContainer {
  min-height: 50vh;
  @extend .display-flex;
  @extend .flex-center;
}

.Loader {
  overflow: hidden;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: $white;
  background: linear-gradient(to right, $white 10%, rgba($black, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: $white;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: $blue;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
  }
}

@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
